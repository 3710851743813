/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GlobalProvider } from '../context/GlobalState';
import ItemOverlay from '../components/ItemOverlay';
import Navigation from '../components/Navigation';
import MainWrapper from './MainWrapper';
import Footer from './Footer';
import { useRef, useEffect } from "react";
import StickyNavWrapper from './StickyNavWrapper';


const Layout = ({ children, aRef }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="home" className="App relative max-w-full pageContent" ref={aRef} >
      <GlobalProvider>
        <div className="global-trigger fixed h-0 w-0"></div>
        <ItemOverlay />
        {/* <StickyNavWrapper> */}
          <Navigation />
        {/* </StickyNavWrapper> */}
        <MainWrapper>
          {children}
        </MainWrapper>
        <Footer />
      </GlobalProvider >
    </div >
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
