import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// https://codepen.io/mahendra_pratap/pen/oNYOWOK

gsap.registerPlugin(ScrollTrigger);


export default function StickyNavWrapper({
    children
}) {

    const ref = useRef(null);
    const headerClasses = ' siteHeader fixed inset-x-0 top-0 transition-all z-10'
    const headerElementClass = 'siteHeader'

    useEffect(() => {

        if (ref.current !== null) {

            var siteHeader = ref.current.querySelector(`.${headerElementClass}`);
            var header_progress = 0;
            var header_direction = 1;

            ScrollTrigger.create({
                trigger: ".pageContent", // defined in layout
                start: "top top",
                end: 'bottom bottom',
                scrub: true,
                markers: false,
                onUpdate: function (self) {
                    header_progress = self.progress.toFixed(2);
                    header_direction = self.direction;
                    var newClassName = "";
                    if (header_progress === '0.00') {
                        newClassName = `${headerElementClass} ${headerElementClass}--top`;
                    } else if (header_progress === '1.00') {
                        newClassName = `${headerElementClass} ${headerElementClass}--notTop ${headerElementClass}--bottom`;
                    } else {
                        newClassName = `${headerElementClass} ${headerElementClass}--notTop`;
                        if (header_direction === 1) {
                            newClassName += ` ${headerElementClass}--unpinned`;
                        }
                    }
                    siteHeader.className = newClassName + headerClasses;
                }
            });
        }
    }, [ref.current])


    return (
        <div
            className="relative"
            ref={ref}
        >
            <div
                className={`${headerClasses}`}
            >
                {children}
            </div>
        </div>
    )
}